<template>
	<w-section :title="$t('notifications.' + value.key) + ' :'">
		<template v-slot:header>
			<w-flex mr-2 shrink>
				<w-divider vertical />
			</w-flex>
			<w-flex align-center d-flex shrink>
				<w-switch class="ma-0" :value="isActive" @input="toggleFamily($event)" />
			</w-flex>
			<w-btn-save :mini="$vuetify.breakpoint.xsOnly" @click="save()" />
		</template>
		<w-layout v-if="isActive" column>
			<w-flex v-for="(type, index) in copiedTypes" :key="type.id">
				<NotificationTypeItem :is-everial="isEverial" :value="type" @input="onInput($event, index)" />
			</w-flex>
		</w-layout>
	</w-section>
</template>

<script>
import NotificationsModuleGuard from '@/mixins/ModulesGuards/Notifications/NotificationsModuleGuard'

export default {
	name: 'NotificationItem',
	components: {
		NotificationTypeItem: () => ({
			component: import('@/components/Notifications/Profile/NotificationTypeItem')
		})
	},
	mixins: [NotificationsModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			copiedTypes: []
		}
	},
	computed: {
		isActive: function () {
			return this.value.is_active || this.value.is_active === null
		},
		isEverial: function () {
			return this.value.key === 'everial'
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				this.copiedTypes = [...val.types]
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.FAMILY_UPDATED, action: this.onFamilyUpdated }]
		},
		onFamilyUpdated: function (family) {
			this.$emit('input', family)
		},
		onInput: function (newVal, index) {
			if (newVal.frequency) {
				this.copiedTypes[index].frequency = newVal.frequency
			}
			if (newVal.detailed !== null) {
				this.copiedTypes[index].detailed = newVal.detailed
			}
			if (newVal.sources !== null) {
				this.copiedTypes[index].sources = newVal.sources
			}
		},
		save: function () {
			const familyId = this.value.id
			const types = this.copiedTypes
			return this.service
				.updateUserPreferences(familyId, types)
				.then(typesRes => {
					let copiedValue = { ...this.copiedValue }
					copiedValue.types = typesRes
					this.$store.dispatch('notifications/updateFamily', copiedValue)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('notifications.profile.preferences_updated'))
				})
				.catch(() => {
					this.copiedTypes = [...this.value.types]
				})
		},
		toggleFamily: function (toggleValue) {
			const familyId = this.value.id
			return this.service.toggleFamily(familyId, toggleValue)
		}
	}
}
</script>
